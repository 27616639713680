define("discourse/plugins/discourse-group-tag-associations/discourse/templates/connectors/before-manage-group-tags/tag-associations", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class="control-group">
    <label class="control-label">{{i18n "group_tag_associations.title"}}</label>
    <div>{{i18n "group_tag_associations.description"}}</div>
  </div>
  
  <div class="control-group">
    <TagChooser
      @tags={{this.model.associated_tags}}
      @allowCreate={{false}}
      @everyTag={{true}}
    />
  </div>
  */
  {
    "id": "5zkZsZsc",
    "block": "[[[10,0],[14,0,\"control-group\"],[12],[1,\"\\n  \"],[10,\"label\"],[14,0,\"control-label\"],[12],[1,[28,[35,0],[\"group_tag_associations.title\"],null]],[13],[1,\"\\n  \"],[10,0],[12],[1,[28,[35,0],[\"group_tag_associations.description\"],null]],[13],[1,\"\\n\"],[13],[1,\"\\n\\n\"],[10,0],[14,0,\"control-group\"],[12],[1,\"\\n  \"],[8,[39,1],null,[[\"@tags\",\"@allowCreate\",\"@everyTag\"],[[30,0,[\"model\",\"associated_tags\"]],false,true]],null],[1,\"\\n\"],[13]],[],false,[\"i18n\",\"tag-chooser\"]]",
    "moduleName": "discourse/plugins/discourse-group-tag-associations/discourse/templates/connectors/before-manage-group-tags/tag-associations.hbs",
    "isStrictMode": false
  });
});