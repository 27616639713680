define("discourse/plugins/discourse-group-tag-associations/initializers/group-tag-associations", ["exports", "discourse/lib/plugin-api"], function (_exports, _pluginApi) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    name: "group-tag-associations",
    initialize() {
      (0, _pluginApi.withPluginApi)("0.11.0", api => {
        api.modifyClass("model:group", {
          asJSON() {
            const attrs = this._super(...arguments);
            if (this.associated_tags) {
              attrs.associated_tags = this.associated_tags;
            }
            return attrs;
          }
        });
      });
    }
  };
});